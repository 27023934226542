import React from 'react';

interface ComponentProps {
  children: any;
  className?: string;
  center?: boolean;
  padding?: boolean;
}

const Container: React.FunctionComponent<ComponentProps> = ({
  children,
  className,
  center,
  padding
}) => (
  <div className={`container ${center ? 'mx-auto' : ''} ${padding ? 'px-4' : ''} ${className}`}>
    <div className={`lg:w-8/12 ${center ? 'mx-auto' : ''}`}>{children}</div>
  </div>
);

Container.defaultProps = {
  className: ``,
  center: false,
  padding: true
} as Partial<ComponentProps>;

export default Container;
