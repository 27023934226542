import * as React from "react"
import Variables from "../../constants/Variables"

interface Props {
  text: string
  link: string
  className?: string
}

export default function SimpleExternalButton({
  text,
  link,
  className = "",
}: Props) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <button
        className={`bg-theme text-md text-white py-2 px-10 rounded inline-flex items-center font-semibold ${className}`}
        style={{ fontFamily: Variables.fontFamilyHeadline, marginTop: 3 }}
      >
        <span>{text}</span>
      </button>
    </a>
  )
}
