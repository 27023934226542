import * as React from 'react';
import { Link } from 'gatsby';
import { useState } from 'react';
import Colors from '../../constants/Colors';

interface ComponentProps {
    children: string,
    to: string,
}

const FooterLink: React.FunctionComponent<ComponentProps> = ({ children, to }) => {
    const [hover, setHover] = useState<boolean>(false);

    const hoverEffect = (): void => setHover(!hover);

    return (
        <Link to={to} title={children}>
            <p 
                className="text-sm mt-1 mb-3" 
                onMouseOver={hoverEffect} 
                onMouseLeave={hoverEffect}
                style={{ color: Colors.lightGrey }}
            >
                {children}
            </p>
        </Link>
    );
}

export default FooterLink;