import React from "react"

interface ComponentProps {
  children: any
  className?: string
  subClassName?: string
  margin?: boolean
  center?: boolean
}

const WideContainer: React.FunctionComponent<ComponentProps> = ({
  children,
  className,
  subClassName,
  margin,
  center,
}) => (
  <div
    className={`container mx-auto ${center ? "text-center" : ""} ${
      margin ? "px-0 md:px-4" : ""
    } ${className}`}
  >
    <div
      className={`lg:w-12/12 mx-auto ${
        center ? "text-center" : ""
      } ${subClassName}`}
    >
      {children}
    </div>
  </div>
)

WideContainer.defaultProps = {
  className: ``,
  subClassName: ``,
  margin: true,
  center: true,
} as Partial<ComponentProps>

export default WideContainer
