const green = "#09282B"
const lightgreen = "#EFF6F6"
const red = "#D94267"
const yellow = "#EEBC4E"
const pink = "#F0B8B7"
const veryLightGrey = "#F9F9F9"
const breadcrumbsGrey = "#6d7688"

export default {
  themeColor: "#245D6B",
  lightThemeColor: "#DEDED9",
  darkThemeColor: "#09282B",
  contrastColor: pink,

  borderColor: "#6B57FF",
  textColor: "#99abce",

  // Colors
  white: "#fff",
  black: "#000",
  almostBlack: "#34454C",
  grey: "#737a96",
  lightGrey: "#DBDBD9",
  veryLightGrey,
  green,
  lightgreen,
  red,
  yellow,
  breadcrumbsGrey,
}
