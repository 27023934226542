import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import FooterLink from "./FooterLink"
import SocialComponent from "./SocialComponent"
import Routes from "../../constants/Routes"
import Colors from "../../constants/Colors"
import Variables from "../../constants/Variables"
import WideContainer from "../layout/WideContainer"
import DownloadApp from "./DownloadApp"

const Footer: React.FunctionComponent<{}> = () => {
  const { site } = useStaticQuery<any>(
    graphql`
      query staticTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const { title } = site.siteMetadata

  return (
    <>
      <DownloadApp />
      <div>
        <div className="footer pb-8 pt-20 bg-darktheme">
          <WideContainer center={false} subClassName={"px-4"}>
            <div className="grid lg:grid-cols-5 sm:grid-cols-1">
              <div>
                <h3 style={{ color: Colors.white }} className="headline mt-2">
                  Alper
                </h3>
                <p className="text-sm pr-4" style={{ color: Colors.lightGrey }}>
                  Information on all the national parks.
                </p>
              </div>
              <div>
                <h4 style={{ color: Colors.white }} className="text-base mb-4">
                  Pages
                </h4>
                <FooterLink to={Routes.HOME}>Front Page</FooterLink>
                <FooterLink to={Routes.COUNTRIES}>
                  Download the app - iOS
                </FooterLink>
                <FooterLink to={Routes.COUNTRIES}>
                  Download the app - Android
                </FooterLink>
                <FooterLink to={Routes.COUNTRIES}>
                  Search Between Parks
                </FooterLink>
              </div>
              <div>
                <h4 style={{ color: Colors.white }} className="text-base mb-4">
                  Popular Parks
                </h4>
                <FooterLink to={Routes.SAXON_SWITZERLAND}>
                  Saxon Switzerland National Park
                </FooterLink>
                <FooterLink to={Routes.CINQUE_TERRE}>
                  Cinque Terre National Park
                </FooterLink>
                <FooterLink to={Routes.PLITVICE}>
                  Plitvice National Park
                </FooterLink>
                <FooterLink to={Routes.SWISS_NATIONAL_PARK}>
                  Swiss National Park
                </FooterLink>
              </div>
              <div>
                <h4 style={{ color: Colors.white }} className="text-base mb-4">
                  Guides
                </h4>
                <FooterLink to={Routes.WILDERNESS_SAFETY}>
                  Wilderness Safety
                </FooterLink>
                <FooterLink to={Routes.RULES_AND_REGULATIONS}>
                  Rules and Regulations
                </FooterLink>
                <FooterLink to={Routes.BEAR_SAFETY}>Bear Safety</FooterLink>
                <FooterLink to={Routes.LIGHTNING_INFORMATION}>
                  Lightning Precautions
                </FooterLink>
              </div>
              <div>
                <h4 style={{ color: Colors.white }} className="text-base mb-4">
                  About
                </h4>
                <FooterLink to={Routes.CONTACT}>Contact</FooterLink>
                <FooterLink to={Routes.HOME}>Home Page</FooterLink>
              </div>
            </div>
            <hr className="mt-16 mb-8" style={{ color: Colors.lightGrey }} />
            <div className="grid lg:grid-cols-2 sm:grid-cols-1">
              <div>
                <SocialComponent />
              </div>
              <div>
                <div className="inline-block text-center px-1 py-1 float-right">
                  <Link to={Routes.PRIVACY_POLICY} title="Privatpolitik">
                    <span style={legalLink}>Privacy Policy</span>
                  </Link>
                </div>
                <div className="inline-block text-center px-1 py-1 float-right">
                  <span className="copyright" style={copyright}>
                    ©{new Date().getFullYear()}, {title}
                  </span>
                </div>
              </div>
            </div>
          </WideContainer>
        </div>
      </div>
    </>
  )
}

const legalLink = {
  color: Colors.lightGrey,
  fontFamily: Variables.fontFamilyText,
  fontSize: 14,
  lineHeight: 2,
  paddingLeft: 20,
  paddingRight: 20,
}

const copyright = {
  color: Colors.lightGrey,
  fontFamily: Variables.fontFamilyText,
  fontSize: 14,
  lineHeight: 2,
  opacity: 1,
}

export default Footer
