export default {
  // Main pages
  SITE_URL: "https://www.getalper.com",
  HOME: "/",
  COUNTRIES: "/countries/",
  PARK_TYPES: "/park-types/",
  SEARCH: "/search/",
  CONTACT: "/contact/",
  THANKS: "/thanks/",

  // INFORMATION PAGES
  BEAR_SAFETY: "/information/bear-safety/",
  LIGHTNING_INFORMATION: "/information/lightning-information/",
  WILDERNESS_SAFETY: "/information/wilderness-safety/",
  RULES_AND_REGULATIONS: "/information/rules-and-regulations/",

  // GUIDES
  DOWN_JACKET_GUIDE: "/gear/down-jacket-ultralight-guide-europe/",
  TENT_GUIDE: "/gear/guide-to-ultralight-tents-for-1-person-in-europe/",

  // TAGS
  TAG_MOUNTAINS: "/tag/mountains/",
  TAG_ALPINE: "/tag/alpine/",
  TAG_CLIFFS: "/tag/cliffs/",
  TAG_LAKES: "/tag/lakes/",
  TAG_FJORD: "/tag/fjord/",
  TAG_FOREST: "/tag/forest/",
  TAG_SMALL: "/tag/small/",
  TAG_COAST: "/tag/coast/",
  TAG_BOG: "/tag/bog/",

  // PREFIXES
  SIGHT_ROUTE: "sight/",
  VISITOR_CENTER_ROUTE: "visitor-center/",
  MOUNTAIN_ROUTE: "mountain/",
  NATURE_SPOT_ROUTE: "nature-spot/",
  SHELTER_ROUTE: "shelter/",
  HIKE_ROUTE: "hike/",
  ACTIVITY_ROUTE: "activity/",

  // APP LINKS
  APP_IOS: "https://apps.apple.com/dk/app/alper-national-parks/id1547175363",
  APP_ANDROID:
    "https://play.google.com/store/apps/details?id=com.kristofferandreasen.alper&hl=en&gl=US",

  // INDIVIDUAL PARK LINKS
  SAXON_SWITZERLAND: "/saxon-switzerland-national-park/",
  CINQUE_TERRE: "/cinque-terre-national-park/",
  PLITVICE: "/plitvice-lakes-national-park/",
  SWISS_NATIONAL_PARK: "/swiss-national-park/",

  // PRIVACY ETC
  PRIVACY_POLICY: "/privacy-policy/",
}
