import React from "react"
import Colors from "../../constants/Colors"
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"

const SocialComponent: React.FunctionComponent = () => {
  const { site } = useStaticQuery<any>(
    graphql`
      query staticSocialComponentQuery {
        site {
          siteMetadata {
            facebookPage
            instagramPage
            twitterPage
            linkedinPage
          }
        }
      }
    `
  )

  const { facebookPage, instagramPage, twitterPage, linkedinPage } =
    site.siteMetadata

  return (
    <div>
      <div className="inline-block text-center px-1 py-1">
        <a href={facebookPage} target="_blank" rel="nofollow noreferrer">
          <FaFacebook style={icon} size={20} />
        </a>
      </div>
      <div className="inline-block text-center px-1 py-1">
        <a href={instagramPage} target="_blank" rel="nofollow noreferrer">
          <FaInstagram style={icon} size={20} />
        </a>
      </div>
      <div className="inline-block text-center px-1 py-1">
        <a href={twitterPage} target="_blank" rel="nofollow noreferrer">
          <FaTwitter style={icon} size={20} />
        </a>
      </div>
      <div className="inline-block text-center px-1 py-1">
        <a href={linkedinPage} target="_blank" rel="nofollow noreferrer">
          <FaLinkedin style={icon} size={20} />
        </a>
      </div>
    </div>
  )
}

const icon = {
  color: Colors.lightGrey,
  opacity: 0.8,
  marginRight: 15,
  cursor: "pointer",
} as React.CSSProperties

export default SocialComponent
