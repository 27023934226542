import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Container from "../layout/Container"

export default function DownloadApp() {
  return (
    <div className="px-4 pb-4 md:pt-4 pt-0">
      <div className="bg-light py-20">
        <Container center={true}>
          <div className="text-center">
            <h2>Never miss a national park on your travels</h2>
            <Container center={true}>
              <p className="text-base">
                Download the app on your phone and get all the information on
                your phone. Do your research on the fly when you need to.
              </p>
            </Container>
            <a
              href="https://apps.apple.com/dk/app/alper-national-parks/id1547175363"
              title="Download Alper app on iOS - National Parks"
              className="pr-4"
            >
              <StaticImage
                src="../../images/app-store.png"
                alt="National Parks App Alper - iOS"
                placeholder="blurred"
                className="w-44"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.kristofferandreasen.alper&hl=en_US&gl=US"
              title="Download Alper app on Android - National Parks"
            >
              <StaticImage
                src="../../images/google-play-badge.png"
                alt="National Parks App Alper - Android"
                placeholder="blurred"
                className="w-44 mt-1"
              />
            </a>
          </div>
        </Container>
      </div>
    </div>
  )
}
